import ModelInlineBase from "@/apps/core/models/modelInlineBase.js";


const modelName = "matakuliah";
const inlineModelListName = "kompetensi_set";
const modelPath = `/${modelName}/`;

class MataKuliah extends ModelInlineBase {
  constructor() {
    let model = {
      id: null,
      kode: "",
      nama: "",
      kompetensi_set: []
    };
    let requiredFields = ["nama", "kode"];
    let inlineModel = { kuantitatif: "", kualitatif: "" };
    let inlineRequiredFields = ["kuantitatif", "kualitatif"];
    super(modelName, inlineModelListName, model, inlineModel,
      requiredFields, inlineRequiredFields, modelPath);
  }
}

export default MataKuliah;